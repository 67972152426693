import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import 'react-datepicker/dist/react-datepicker.css';
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
import theme from './theme.js'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
//import AlertTemplate from 'react-alert-template-basic'
import './assets/css/index.css'
import checkIcon from './assets/img/check-circle.svg'
import banIcon from './assets/img/ban.svg'

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const AlertTemplate = ({ style, options, message, close }) => (
  <div
    className="px-4 flex flex-row"
    style={{
      ...style,
      backgroundColor: (options.type === 'success') ? '#10B981' : '#EF4444',
      width: 384,
      height: 64,
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {options.type === 'success' && 
      <img src={checkIcon} style={{ width: 15.5 }} alt="check" />
    }
    {options.type === 'error' && 
      <img src={banIcon} style={{ width: 15.5 }} alt="ban" />
    }
    {options.type === 'success' && 
      <span className="text-white text-sm ml-2">{message}</span>
    }
    {options.type === 'error' && 
      <span className="text-white text-sm ml-2">{message}</span>
    }
  </div>
)

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

ReactDOM.render(
  <SidebarProvider>
    <Suspense fallback={<ThemedSuspense />}>
      <Windmill theme={theme} light>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </Windmill>
    </Suspense>
  </SidebarProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
